const axios = require('axios');
import {config} from '@/assets/js/Utils.js';

export function getScreenId(campaignId,screenId,groupId,amount,hasLoyalty){
  let path = `/screen/get/id?campaignId=${campaignId}&screenId=${screenId}&groupId=${groupId}&amount=${amount}&hasLoyalty=${hasLoyalty}`

  return axios.get(config.WS_URL + path)
}

export function postForm(campaignId,screenId,inputId,value,groupId,amount,hasLoyalty,screenGroupId){
  let path = `/stats/add?campaignId=${campaignId}&screenId=${screenId}&inputId=${inputId}&action=TEXT&groupId=${groupId}&value=${value}&amount=${amount}&hasLoyalty=${hasLoyalty}&screenGroupId=${screenGroupId}`

  return axios.post(config.WS_URL + path)
}