<template>
  <div class="home">
    <CommentPage />
  </div>
</template>

<script>
import CommentPage from '@/components/CommentPage.vue'

export default {
  name: 'Home',
  components: {
    CommentPage
  }
}
</script>
<style scoped>
.home{
  height: 100%;
}
</style>
