<template>
        
    <div class="commentPage d-flex flex-column ">

        <v-avatar class="align-self-center" size="200" tile>
            <v-img
            contain
            :src="dataLogo.value"
            ></v-img>
        </v-avatar>

        <span :data-idTitle="dataTitle.id" class="my-6 text-center primary--text text-h5 font-weight-bold">{{dataTitle.value}}</span>


        <v-form
        ref="commentPageForm"
        class=" form px-4"
        v-model="valid"
        data-idSmallInput
        lazy-validation
        >

            <v-text-field
            v-model="textValue"
            :label="dataSmallInputs.value"
            height="32px"
            color="primary"
            validate-on-blur 
            rounded 
            dense 
            hide-details 
            outlined
            required
            ></v-text-field>

            <v-textarea
            class="mt-4"
            v-model="textareaValue"
            :label="dataTextarea.value"
            color="primary"
            validate-on-blur 
            dense 
            hide-details 
            outlined
            required
            ></v-textarea>

            <v-btn
            color="primary"
            class="mt-4 d-flex btnSubmit"
            :rules="[rules.notEmpty]"
            @click="validateForm"
            >
                Send
            </v-btn>
        </v-form>
    </div>

</template>
<script>
const ScreenService = require("@/services/GetScreenService.js")
import { v4 as uuidv4 } from 'uuid';

export default {
    name: "CommentPage",
    data(vm){
        return{
            uuid: uuidv4(),
            valid: true,
            campaignId: null,
            screenId: null,
            groupId: null,
            amount: null,
            nbProduct: null,
            hasLoyalty :null,
            textareaValue:"",
            textValue:"",
            rules: {
                notEmpty: value => vm.isNotNull(value) ? true : 'this field cannot be empty'
            },
            dataSmallInputs: {
                id: null,
                value: null
            },
            dataTextarea: {
                id: null,
                value: null
            },
            dataLogo: {
                id: null,
                value: null
            },
            dataTitle: {
                id: null,
                value: null
            },
            dataDescription: {
                id: null,
                value: null
            }
        }

    },
    created(){
        this.campaignId = this.$route.query.campaignId
        this.screenId = this.$route.query.screenId
        this.groupId = this.$route.query.groupId
        this.amount = this.$route.query.amount
        this.hasLoyalty = this.$route.query.hasLoyalty

        ScreenService.getScreenId(this.campaignId,this.screenId,this.groupId,this.amount,this.hasLoyalty).then(({status, data}) => {

            if(status === 200 && this.isNotNull(data)){
                
                for(let i = 0; i<data.inputs.length; i++){

                    switch (data.inputs[i].name) {
                        case "QR_SMALL_INPUT":
                            this.dataSmallInputs.value = data.inputs[i].value
                            this.dataSmallInputs.id = data.inputs[i].id
                            break;
                        case "QR_TEXTAREA":
                            this.dataTextarea.value = data.inputs[i].value
                            this.dataTextarea.id = data.inputs[i].id
                            break;
                        case "LOGO":
                            this.dataLogo.value = data.inputs[i].value
                            this.dataLogo.id = data.inputs[i].id
                            break;
                        case "TITLE":
                            this.dataTitle.value = data.inputs[i].value
                            this.dataTitle.id = data.inputs[i].id
                            break;
                        case "DESCRIPTION":
                            this.dataDescription.value = data.inputs[i].value
                            this.dataDescription.id = data.inputs[i].id
                            break;
                    }

                }

            }

        })
    },
    methods: {

        validateForm(){

            if(this.$refs.commentPageForm.validate()){

                ScreenService.postForm(this.campaignId,this.screenId,this.dataSmallInputs.id,this.textValue,this.groupId,this.amount,this.hasLoyalty,this.uuid)
                ScreenService.postForm(this.campaignId,this.screenId,this.dataTextarea.id,this.textareaValue,this.groupId,this.amount,this.hasLoyalty,this.uuid)
                // this.$router.push({ name: 'EndPage' })
                this.$router.push({ path: `/endpage?logo=${this.dataLogo.value}` })

            }
            
        },
    }
}
</script>
<style>
.commentPage{
    height: 100%;
}
.btnSubmit{
    float: right;
}
.alignCenter{
    text-align: center
}

.form{
    width: 100%;
}
</style>