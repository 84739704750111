<template>

  <div class="endPage d-flex flex-column align-center">
      <v-img
          class="mt-6"
          width="200"
          contain
          :src="logo"
      ></v-img>

      <span class="center alignCenter primary--text text-h1 font-weight-bold">Thanks</span>
      <span class="mt-4 alignCenter primary--text text-h4 font-weight-bold">See you soon !</span>

  </div>

</template>

<script>

  export default {
  name: "EndPage",
  data(){
    return{
      logo: null
    }
  },
  created(){
    this.logo = this.$route.query.logo
  }
}
</script>
<style scoped>

.center{
  margin-top: 120px;
}
</style>