export const config = {
  WS_URL: "https://smartengages-ws-wldd-tools.pubqlf.co.as8677.net",
};

export function isNotNull(val){
if(val != null && val != "null" && val !== "" && val !== "undefined" && val !== undefined && val !== "-1" && val !== -1 && val !== "[]"){
    if(!Array.isArray(val)){
        return true;
    }else if(val.length > 0){
        return true;
    }
}
return false;
}

export function isNull(val){
if(val == null || val == "null" || val === "" || val === "undefined" || val === undefined || val === "-1" || val === -1 || val === "[]"){
    return true;
}else{
    if(!Array.isArray(val)){
        return false;
    }else if(val.length === 0){
        return true;
    }
}
return false;
}